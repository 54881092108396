import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@core';
import { Text } from '@components';
import { ImageCore } from '@components/image';

import WindowsDark from '@images/platform-icons/windows-black.svg';
import WindowsLight from '@images/platform-icons/windows-light.svg';
import iOSDark from '@images/platform-icons/ios-black.svg';
import iOSLight from '@images/platform-icons/ios-light.svg';
import MacOSDark from '@images/platform-icons/mac-os-black.svg';
import MacOSLight from '@images/platform-icons/mac-os-light.svg';
import AndroidDark from '@images/platform-icons/android-black.svg';
import AndroidLight from '@images/platform-icons/android-light.svg';
import AndroidTVDark from '@images/platform-icons/android-tv-black.svg';
import AndroidTVLight from '@images/platform-icons/android-tv-light.svg';
import FireTVDark from '@images/platform-icons/fire-tv-black.svg';
import FireTVLight from '@images/platform-icons/fire-tv-light.svg';
import LinuxDark from '@images/platform-icons/linux-black.svg';
import LinuxLight from '@images/platform-icons/linux-light.svg';
import AppleTvDark from '@images/platform-icons/apple-tv-black.svg';
import AppleTvLight from '@images/platform-icons/apple-tv-light.svg';

const ICONS = {
  dark: {
    windows: WindowsDark,
    iOS: iOSDark,
    macOS: MacOSDark,
    android: AndroidDark,
    androidTv: AndroidTVDark,
    fireTv: FireTVDark,
    linux: LinuxDark,
    appleTv: AppleTvDark,
  },
  light: {
    windows: WindowsLight,
    iOS: iOSLight,
    macOS: MacOSLight,
    android: AndroidLight,
    androidTv: AndroidTVLight,
    fireTv: FireTVLight,
    linux: LinuxLight,
    appleTv: AppleTvLight,
  },
};

const textColor = {
  dark: 'darkBlue',
  light: 'white',
};

const AvailablePlatforms = ({
  text,
  iconWrapperProps,
  flexDirection,
  variant = 'dark',
  centered,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      display="inline-flex"
      flexDirection={flexDirection || { _: 'column', md: 'row' }}
      columnGap={16}
    >
      <Box>
        <Text.Body2 textAlign={centered ? 'center' : 'left'} color={textColor[variant]}>
          {text || t('common:availableOnDevices')}
        </Text.Body2>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        columnGap={16}
        {...iconWrapperProps}
      >
        <ImageCore src={ICONS[variant].windows} alt="Windows" />
        <ImageCore src={ICONS[variant].macOS} alt="macOS" />
        <ImageCore src={ICONS[variant].linux} alt="Linux" />
        <ImageCore src={ICONS[variant].iOS} alt="iOS" />
        <ImageCore src={ICONS[variant].android} alt="Android" />
        <ImageCore src={ICONS[variant].androidTv} alt="Android TV" />
        <ImageCore src={ICONS[variant].fireTv} alt="Amazon Fire TV" />
        <ImageCore src={ICONS[variant].appleTv} alt="Apple TV" />
      </Box>
    </Box>
  );
};

export default AvailablePlatforms;
